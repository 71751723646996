import { useRef } from 'react';
import './ContactUs.css'
import Swal from 'sweetalert2';
import emailjs from '@emailjs/browser'
import { useLocation } from 'react-router-dom';

function ContactUs({ match }) {
    const location = useLocation();
    const content = location.state?.content;
    const form = useRef(null)
    
    const sendEmail = e => {
        e.preventDefault();
        emailjs
      .sendForm(
        "service_dv62f4s",
        "template_eo5pgtn",
        form.current,
        "aEtlRW16jAsVhViEJ"
      )
      .then(
        (result) => {
              e.target.reset();
              Swal.fire({
                  title: "Success!",
                  text: "Great! We've received your message. Our team will review it and get back to you as soon as possible.",
                  icon: 'success',
                  confirmButtonText:'OK'
              })
              
        },
          (error) => {
              Swal.fire({
                  title: 'Error',
                  text: 'Something went wrong. Please, try later.',
                  icon: 'error',
                  confirmButtonText:'OK'
            })
        }
      );
    }

    return (
        <main className="container">
            <div className='header'>
                <h1 className='logo'>AIR IT-SOLUTIONS</h1>
            </div>
            <div className="banner-contact">
                <h1 className="banner-txt"><span>Do You Fancy Becoming Our Partner ?</span></h1>
            </div>
            <div className='line'></div>
            <div className='form-wrapper'>
                <div className='intro'>
                    <h1 className='intro-title'>We Constantly Seek New Talent</h1>
                    <p className='intro-desc'>We believe in the power of expertise. Our team comprises seasoned specialists, meticulously selected for each project. If you're a virtuoso in your field and seek collaboration at its finest, click Send to join forces with us.</p>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <div className='top-info'>
                        <div className='field'>
                            <label>First Name</label>
                            <input type="text" name="user_first_name" required />
                        </div>
                        <div className='field'>
                            <label>Last Name</label>
                            <input type="text" name="user_last_name" required />
                        </div>
                        <div className='field'>
                            <label>Email*</label>
                            <input type="email" name="user_email" required />
                        </div>
                    </div>
                    <div className='field'>
                        <label>Message</label>
                        <label className='lbl'>
                            <textarea name="message" required >{content ? "Please contact me regarding "+content : ""}</textarea>
                        </label>
                    </div>
                        <input className='btnSubmit' type="submit" value="SEND" />
                </form>
            </div>
        </main>
    );
}

export default ContactUs;