import Card from '../components/Card';
import Header from '../components/Header';
import './Home.css'
import { useRef } from 'react';
import { motion, useAnimation } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useEffect } from "react";
import Footer from '../components/Footer';

function Home() {
  const about = useRef(null)
  const mission = useRef(null)
  const services = useRef(null)

  const partenerImg = useAnimation()
  const control1 = useAnimation()
  const control2 = useAnimation()
  const controls = useAnimation()
  const headerAnim = useAnimation()
  const [ref1, inView1] = useInView({triggerOnce:true})
  const [ref2, inView2] = useInView({ triggerOnce: true })
  const [cards, inViewCards] = useInView({ triggerOnce: true });
  const [partImg, inViewPartImg] = useInView({ triggerOnce: true })
  const [header, inViewHeader]=useInView({ triggerOnce: true })
  
  const aboutImg ={
    visible: { opacity: 1, scale: 1, transition:{duration:1.5, ease: 'easeInOut'}  },
    hidden: { opacity: 0, scale: 0 },
  }

  const flipVariants = {
    hidden: { transform: 'rotateY(180deg)' },
    visible: { transform: 'rotateY(0deg)' },
  };

  const flipTransition = {
    duration: 0.5,
    ease: 'easeInOut',
  };
  const partnerVariants = {
    hidden: { y: '-100%', opacity: 0, },
    visible: { y: 0, opacity: 1, transition: { duration: 1.5 } },
  };

  useEffect(() => {
    if (inViewHeader) {
      headerAnim.start({
        opacity: 1,
        y:0,
      transition: { duration: 1, ease: 'easeInOut' },
    });
    }
    if (inView1) {
      control1.start("visible");
    } 
    if (inView2) {
      control2.start("visible");
    } 
    if (inViewCards) {
      controls.start("visible")
    }
    if (inViewPartImg) {
      partenerImg.start("visible")
    }
    
  }, [control1, control2, controls, partenerImg,headerAnim, inView1, inView2, inViewCards, inViewPartImg, inViewHeader]);

  return (
      <>
      <div className="home-container">
      <Header services={services} about={about} mission={mission} />
        <motion.div
          ref={header}
        initial={{ opacity: 0, y:-20}}
          animate={headerAnim}
        className='txtBanner'>
      <h1 className='txtBanner'>Welcome.</h1>
        </motion.div>
        <div className='banner'>
        </div>
      <div ref={about} className='about-us'>
          <motion.div
            id='animAbout1'
            variants={aboutImg}
            animate={control1}
            ref={ref1}
            initial='hidden'
            className='imgAbout'
          ></motion.div>
        <p className='txtAbout'>In a fast-paced, ever-changing market, AiR IT-Solutions emerges as a beacon of innovation and expertise. We're not just a company; we're your partners in progress. With a focus on BPM, ERP, application development, IT consulting, and project management, we redefine the way businesses work in the IT realm.</p>
      </div>
      <div ref={mission} className='our-mission'>
        <p className='txtAbout'>Our mission is simple yet powerful: we're here to empower your business with cutting-edge solutions. We provide the expertise and tools needed to create efficient organizational systems, fostering innovations that let you seize opportunities in thriving times and fortify your position during economic challenges.</p>
          <motion.div
            id='animAbout2'
            variants={aboutImg}
            animate={control2}
            ref={ref2}
            initial='hidden'
            className='imgMission'></motion.div>
      </div>
      <h1 ref={services} className='subheader'>Our Services</h1>
        <div className='services'>
          <motion.div
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}>
            <Card
              title="Consulting"
              description="We lead the way in shaping ICT strategies, streamlining processes, making pivotal decisions, and choosing optimal technologies. When you need clarity and direction, we provide the answers." />
          </motion.div>
        <motion.div
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}
            className="card">
        <Card
          title="ERP System Implementation"
            description="Resource optimization is at the heart of our mission. Let us transform your ERP system into a strategic advantage." />
          </motion.div>
          <motion.div
            ref={cards}
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}
            className="card">
          <Card
          title="IT Project Management"
            description="Expect nothing less than professional excellence as we deliver projects that meet the highest standards. We're your partners in project success." />
          </motion.div>
        <motion.div
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}
            className="card">
          <Card
          title="Application Development"
            description="Tailored applications are our specialty. We craft solutions that align perfectly with your unique needs and goals." />
          </motion.div>
        <motion.div
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}
            className="card">
          <Card
          title="Multilingual Programming"
          description="Challenges fuel our passionate team. No matter how complex, we're ready to conquer them with expertise and finesse." />
       </motion.div>
       <motion.div
            initial="hidden"
            animate={controls}
            variants={flipVariants}
            transition={flipTransition}
            className="card"> 
          <Card
          title="BPM Analysis and Modeling"
              description="Chaos becomes order with our structured, standardized, and detail-oriented approach. We bring precision to every industry." />
        </motion.div>
      </div>
        <h1 className='subheader'>Partners</h1>
        <motion.div
        ref={partImg}
        initial="hidden"
        animate={partenerImg}
        variants={partnerVariants}>
          <a href='https://taxiszczecin.com.pl/' target="_blank" rel="noopener noreferrer">
          <div className='air-logo'>
        </div></a>
        </motion.div>
      </div>
      <Footer/>
      </>
    )
}

export default Home;