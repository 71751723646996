import { Routes, Route, HashRouter } from 'react-router-dom';
import './App.css';
import Home from './pages/Home';
import ContactUs from './pages/ContactUs';
import OurTeam from './pages/OurTeam';

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/our-team' element={<OurTeam/>} />
        <Route path='/contact-us' element={<ContactUs/>} />
      </Routes>
    </HashRouter>
  );
}

export default App;
