import './Card.css';
import { Link } from 'react-router-dom';

const Card=({title, description})=> {
    return (
        <div className='container-card'>
            <div className='card-top'>
                <Link to='/contact-us' state={{ content: title }} ><div className='card-img'></div></Link>
                <h2 className='card-title'>{ title}</h2>
            </div>
            <div className='card-desc'>
                {description}
            </div>
        </div>
    )
}

export default Card;