import './Footer.css'
import  location  from '../assets/icons/location.png';
import  mail from '../assets/icons/mail.png'
import phone from '../assets/icons/phone.png'
import ing from '../assets/icons/ing.jpg'
import agricole from '../assets/icons/agricole.jpg'
import facebook from '../assets/icons/facebook.png';
import instagram from '../assets/icons/instagram.png';
import linkedin from '../assets/icons/linkedin.png';


export default function Footer() {
    return (
        <footer>
            <div className='contact'>
                <h1 className='txt-contact'>CONTACT US</h1>
                <div className='email'>
                    <img src={mail} alt='email'/>
                    <p> office@airit.pl</p>
                </div>
                <div className='phone'>
                    <img src={ phone} alt='phone' /> 
                    <p> 513 940 394</p>
                </div>
                <div className='address'>
                    <img src={location} alt='location' />
                    <p> ul. Krzysztofa Kolumba 41,<br/>
                    70-035 Szczecin</p>
                </div>                
            </div>
            <div className='follow'>
                <h1 className='txt-contact'>FOLLOW US</h1>
                <div className='icons'>
                        <a href='https://www.facebook.com/people/AIR-IT-Solutions/61551778049162/'><img src={facebook} className='icon' alt='Facebook' /></a>
                        <a href='https://www.linkedin.com/in/air-it-solutions-2abb82293/'><img src={linkedin} className='icon' alt='Facebook' /></a>
                        <a href='https://www.instagram.com/air_it_solutions/'><img src={instagram} className='icon' alt='Facebook' /></a>
                </div>
            </div>
            <div className='payment'>
                <h3 className='top-footer'>
                    NIP: PL8522684332 <br/>
                    REGON: 522582676 <br/>
                    KRS: 0000981858 <br />
                </h3>
                <h3>
                    CREDIT AGRICOLE: PL 74 1940 1076 3246 1006 0000 0000  <img className='agricole-logo' src={agricole } alt='agricole' /> <br />
                    ING BANK ŚLĄSKI : PL 94 1050 1559 1000 0090 8305 9742 <img src={ing } className='ing-logo' alt='ing'/> <br />
                </h3>
            </div>
            
        </footer>
    )
}