import './OurTeam.css'
import { Link } from 'react-router-dom'
import '../components/Header.css'
import Footer from '../components/Footer'

export default function OurTeam() {
    return (
        <div className='container'>
            <header>
            <div className='btns'>
                <h1 className='logo'>AIR IT-SOLUTIONS</h1>
                <Link to='/contact-us' className='btnGradient cta'>
                    WORK WITH US
                </Link>
            </div>
        </header>
            <div className='banner-team'>
                <h1>Meet our team</h1>
                <p className='p-team'>In our dynamic workspace, we embrace a culture that thrives on creativity, dedication, and integrity. With a focus on continuous learning and growth, our team members are not just developers, designers, and strategists; they are passionate problem-solvers dedicated to pushing the boundaries of what's achievable.</p>
            </div>
            <div className='members'>
                <div className='card1'>
                    <div className='avatar m'></div>
                    <h1 className='name-top'>Kordian Justek</h1>
                    <div className='card-desc'>
                        <h2 className='name'>Kordian Justek</h2>
                        <span className='position'>CEO</span>
                        <h3><span className='title'>Email address:</span> k.justek@airit.pl</h3>
                        <h3><span className="title">Phone number:</span><br/> +48 453 230 908</h3>
                    </div>
                </div>
                <div className='card1'>
                    <div className='avatar m-2'></div>
                    <h1 className='name-top'>Maciej Karbowiak</h1>
                    <div className='card-desc'>
                        <h2 className='name' >Maciej Karbowiak</h2>
                        <span className='position'>CMO</span>
                        <h3><span className='title'>Email address:</span> m.karbowiak@airit.pl</h3>
                        <h3><span className="title">Phone number:</span><br/> +48 506 514 180</h3>
                    </div>
                </div>
                <div className='card1'>
                    <div className='avatar w'></div>
                    <h1 className='name-top'>Wiktoria Bach</h1>
                    <div className='card-desc'>
                        <h2 className='name'>Wiktoria Bach</h2>
                        <span className='position'>Social Media Specialist</span>
                        <h3><span className='title'>Email address:</span> w.bach@airit.pl</h3>
                        <h3><span className="title">Phone number:</span><br/> +48 577 450 110</h3>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}