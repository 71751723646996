import './Header.css'
import '../index.css'
import { Link } from 'react-router-dom'

const Header = ({ about, mission, services }) => {
    
    const scrollDown = (elemRef) => {
        window.scrollTo({
            top: elemRef.current.offsetTop,
            behavior:'smooth'
        })
    }
    return (
        <header>
            <h1 className='logo'>AIR IT-SOLUTIONS</h1>
            <div className='menu-btns' >
                <Link to="" onClick={()=>scrollDown(about)} className='btnGradient'>
                    ABOUT US
                </Link>
                <Link onClick={()=>scrollDown(mission)} className='btnGradient'>
                    OUR MISSION
                </Link>
                <Link onClick={()=>scrollDown(services)} className='btnGradient'>
                    OUR SERVICES
                </Link>
                <Link to="/our-team" className='btnGradient'>
                    OUR TEAM
                </Link>
                <Link to='/contact-us' className='btnGradient cta'>
                    WORK WITH US
                </Link>
            </div>
            

        </header>
    )
}

export default Header;